export default {
  nav: [
    'Home', 'About Us', 'Vehicle Types', 'Doroob Centers', 'FAQs', 'Contact US'
  ],
  language: 'Language',
  who: 'Who We Are ?',
  aboutPart1: 'What is Doroob company and its field of business?',
  aboutPart2: 'Al-Doroob is a Syrian company that started working in the field of technical inspection of motorized vehicles by investing in technical inspection lanes under an agreement with the Ministry of Transport, with expertise, competencies and Syrian manpower, aiming to maintain public safety by securing basic safety conditions in any vehicle and working to facilitate transactions for citizens',
  inspection: 'The most important inspection points related to the Doroob report',
  inspectionPoints: ['Internal state inspection',
    'Exterior body inspection',
    'Front lights inspection',
    'Suspension systems inspection',
    'Braking system inspection',
    'Tire quality inspection',
    'Back lights inspection',
    'Gas emission inspection'],
  linesSm: ['Internal',
    'Exterior body',
    'Front lights',
    'Suspension systems',
    'Braking system',
    'Tire quality',
    'Back lights',
    'Gas emission'],
  slider: {
    cardTitle: 'With you all the way',
    cardDesc: 'Contributing to raising the level of traffic safety by providing periodic technical inspection services for cars and vehicles in the Syrian Arab Republic',
    cardBtn: 'Discover more'
  },
  fixedBtn: 'ِAppointment',
  location_info: 'Location details',
  stations: {
    damascus: 'Damascus Station',
    latakia: 'Lattakia Station',
    tartus: 'Tartus Station',
    homs: 'Homs Station',
    hama: 'Hama Station',
    aleppo: 'Aleppo Station',
    damascusMobile: 'Damascus',
    latakiaMobile: 'Lattakia',
    tartusMobile: 'Tartus',
    homsMobile: 'Homs',
    hamaMobile: 'Hama',
    aleppoMobile: 'Aleppo'
  },
  serviceCenters: {
    title: 'Doroob Centers',
    miniTitle: 'In most Syrian governorates',
    desc: 'The periodic inspection team is at your service to inspect your vehicles in the various branches of the company and soon throughout the Syrian Arab Republic'
  },
  centers: {
    centerName: 'Center',
    address: 'Address',
    positions: [[
      { name: 'مركز الزبلطاني', add: 'دمشق - الزبلطاني - مديرية نقل دمشق' },
      { name: 'مركز حرستا', add: 'دمشق - حرستا - مديرية نقل ريف دمشق' },
      { name: 'مركز النبك', add: 'دمشق - النبك - مكتب المرور والمواصلات' }
    ], [
      { name: 'مركز حمص', add: 'حمص - مديرية النقل' }
    ], [
      { name: 'مركز حماة', add: 'حماة - مديرية النقل' }
    ], [
      { name: 'مركز حلب', add: 'حلب - النقارين - مديرية المواصلات بحلب' }
    ], [
      { name: 'مركز طرطوس', add: 'طرطوس - جانب مدرسة السواقة' }
    ], [
      { name: 'مركز اللاذقية', add: 'اللاذقية -  مديرية النقل' }
    ]]
  },
  contactUs: {
    title: 'Contact Us',
    description: 'We are always ready to solve your problems, just send us and we will respond to your inquiries as soon as possible',
    name: 'Name',
    city: 'City',
    phoneNumber: 'Phone Number',
    message: 'Message',
    send: 'Send',
    quickLinks: 'Quick links',
    phone: 'Phone',
    email: 'Email Address',
    socialLink: 'Social Links',
    fax: 'Fax',
    copyRight: 'Doroob Vehicle Inspection Company, All Rights Reserved',
    privacyPolicy: 'Privacy Policy',
    terms: 'Terms and Conditions',
    damas: 'Damascus',
    homs: 'Homs',
    aleppo: 'Aleppo',
    tartous: 'Tartous',
    hama: 'Hama',
    latakia: 'Lattakia',
    nameReq: 'Name is Required',
    nameVal: 'Name is Invalid',
    phoneVal: 'Number is Invalid',
    cityReq: 'City is Required',
    messageReq: 'Message is Required',
    required: 'Required',
    branches: 'Our Branches',
    about: 'About Doroob',
    booking: 'Instant Booking'
  },
  vehicleTypes: {
    title: 'Vehicle Types',
    description: 'We provide periodic inspection services by experienced and specialized professionals for all types and sizes of vehicles',
    motors: 'Motorcycles',
    buses: 'Buses',
    cars: 'Tourist Cars',
    pickUp: 'Transport Trucks',
    trucks: 'Trucks'
  },
  whyUs: {
    head: 'Confident Leadership',
    title: 'Why should you rely on us?',
    reasons: ['Comprehensive inspection', 'Latest technology', 'Road safety', 'Maintenance report after inspection']
  }
}
