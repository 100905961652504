import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import VueI18n from 'vue-i18n'
import vuetify from './plugins/vuetify'
import messages from './lang/local'
import PrimeVue from 'primevue/config'
import VueLottiePlayer from 'vue-lottie-player'
import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)
Vue.use(VueLottiePlayer)
Vue.use(PrimeVue)
Vue.config.productionTip = false

Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: localStorage.getItem('locale') || 'ar',
  messages
})

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App)
}).$mount('#app')
Vue.directive('click-outside', {
  bind (el, binding, vnode) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        vnode.context[binding.expression](event)
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unbind (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
})
