import Vue from 'vue'
import VueRouter from 'vue-router'
import paths from './paths'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  // base: '/app/',
  routes: paths.concat([{
    path: '*'
    // redirect: '/app'
  }]),
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    }
    if (to.hash) {
      return {
        selector: to.hash
      }
    }
    return {
      x: 0,
      y: 0
    }
  }
})

router.beforeEach((to, from, next) => {
  // const needAuth = ['/offers']
  // if (needAuth.includes(to.path)) {
  //   store.dispatch('setToken')
  // }
  // store.dispatch('setToken')
  next()
})

export default router
