export default {
  nav: [
    'الرئيسية', 'حولنا', 'أنواع المركبات', 'مراكز دروب', 'الأسئلة الشائعة', 'تواصل معنا'
  ],
  language: 'اللغة',
  who: 'من نحن ؟',
  aboutPart1: 'من هي شركة دروب وما مجال عملها ؟',
  aboutPart2: 'الدروب الآمنة هي شركة سورية بدأت بالعمل في مجال الفحص الفني للمركبات الآلية عبر استثمار مسارب الفحص الفني بموجب اتفاق مع وزارة النقل وذلك بخبرات وكفاءات وأيدي عاملة سورية، تهدف على الحفاظ على السلامة العامة من خلال تأمين شروط الأمان الأساسية في أي مركبة وتعمل على تسهيل المعاملات على المواطنين',
  inspection: 'أهم نقاط الفحص المتعلقة بتقرير دروب',
  inspectionPoints: ['فحص الحالة الداخلية',
    'فحص الهيكل الخارجي',
    'فحص الأضواء الأمامية',
    'فحص أنظمة التعليق',
    'فحص نظام المكابح',
    'فحص جودة الإطارات',
    'فحص الإنارات الخلفية',
    'فحص انبعاث الغازات'],
  linesSm: ['الداخلية',
    'الهيكل الخارجي',
    'الأضواء الأمامية',
    'أنظمة التعليق',
    'نظام المكابح',
    'الإطارات',
    'الأضواء الخلفية',
    'انبعاث الغازات'],
  slider: {
    cardTitle: 'معك على طول الطريق',
    cardDesc: 'المساهمة في رفع مستوى السلامة المرورية عن طريق تقديم خدمات الفحص الفني للسيارات والمركبات في الجمهورية العربية السورية',
    cardBtn: 'اكتشف المزيد'
  },
  fixedBtn: 'احجز موعداََ',
  location_info: 'تفاصيل الموقع',
  stations: {
    damascus: 'مركز دمشق وريف دمشق',
    latakia: 'مركز اللاذقية',
    tartus: 'مركز طرطوس',
    homs: 'مركز حمص',
    hama: 'مركز حماة',
    aleppo: 'مركز حلب',
    damascusMobile: 'دمشق وريفها',
    latakiaMobile: 'اللاذقية',
    tartusMobile: 'طرطوس',
    homsMobile: 'حمص',
    hamaMobile: 'حماة',
    aleppoMobile: 'حلب'
  },
  centers: {
    centerName: 'اسم المركز',
    address: 'العنوان',
    positions: [[
      { name: 'مركز الزبلطاني', add: 'دمشق - الزبلطاني - مديرية نقل دمشق' },
      { name: 'مركز حرستا', add: 'دمشق - حرستا - مديرية نقل ريف دمشق' },
      { name: 'مركز النبك', add: 'دمشق - النبك - مكتب المرور والمواصلات' }
    ], [
      { name: 'مركز حمص', add: 'حمص - مديرية النقل' }
    ], [
      { name: 'مركز حماة', add: 'حماة - مديرية النقل' }
    ], [
      { name: 'مركز حلب', add: 'حلب - النقارين - مديرية المواصلات بحلب' }
    ], [
      { name: 'مركز طرطوس', add: 'طرطوس - جانب مدرسة السواقة' }
    ], [
      { name: 'مركز اللاذقية', add: 'اللاذقية -  مديرية النقل' }
    ]]
  },
  serviceCenters: {
    title: 'مراكز دروب',
    miniTitle: 'في معظم المحافظات السورية',
    desc: 'فريق عمل الفحص الفني في خدمتكم لفحص مركباتكم في مختلف فروع الشركة وقريباً في جميع أنحاء الجمهورية العربية السورية'
  },
  whyUs: {
    head: 'قيادة بثقة',
    title: 'لماذا عليك الاستعانة بنا؟',
    reasons: ['فحص شامل', 'أحدث وسائل التكنولوجيا', 'أمان على الطرقات', 'تقرير صيانة بعد الفحص ']
  },
  contactUs: {
    title: 'تواصل معنا',
    description: 'نحن دوماً على أتم الاستعداد لحل مشاكلكم فقط أرسلوا لنا وسنجيب على استفساراتكم بأسرع وقت ممكن',
    name: 'الاسم',
    city: 'المدينة',
    phoneNumber: 'رقم الهاتف',
    message: 'الرسالة',
    send: 'إرسال',
    quickLinks: 'روابط سريعة',
    phone: 'رقم الهاتف',
    email: 'البريد الإلكتروني',
    socialLink: 'روابط مواقع التواصل',
    fax: 'الفاكس',
    copyRight: 'شركة دروب لفحص المركبات، جميع الحقوق محفوظة',
    privacyPolicy: 'سياسة الخصوصية',
    terms: 'الشروط و الأحكام',
    damas: 'دمشق',
    homs: 'حمص',
    aleppo: 'حلب',
    tartous: 'طرطوس',
    hama: 'حماة',
    latakia: 'اللاذقية',
    nameReq: 'الاسم مطلوب',
    nameVal: 'الاسم غير صالح',
    phoneVal: 'الرقم غير صالح',
    cityReq: 'المدينة مطلوبة',
    messageReq: 'الرسالة مطلوبة',
    required: 'مطلوب',
    branches: 'فروعنا',
    about: 'حول دروب',
    booking: 'الحجز الفوري'
  },
  vehicleTypes: {
    title: 'أنواع المركبات',
    description: 'نقدم خدمات الفحص الفني بأيدي خبراء ومختصين ولجميع أنواع المركبات وبمختلف الأحجام',
    motors: 'الدراجات النارية',
    buses: 'الباصات',
    cars: 'السيارات السياحية',
    pickUp: 'شاحنات النقل',
    trucks: 'الشاحنات'
  }
}
