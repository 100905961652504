/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */

import lazyLoading from './lazyLoading'
export default [
  {
    path: '/',
    component: lazyLoading('Home')
  },
  {
    path: '/faqs',
    component: lazyLoading('Faqs')
  }
  // {
  //   path: '/reservation',
  //   component: lazyLoading('Reservation')
  // }
]
