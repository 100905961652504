<script>
export default {
  name: 'Direction',
  created () {
    this.$vuetify.rtl = localStorage.getItem('rtl') !== null ? JSON.parse(localStorage.getItem('rtl')) : true
  },
  methods: {
    $setLang (lang) {
      this.$i18n.locale = lang
      this.$vuetify.rtl = lang === 'ar'
      localStorage.setItem('locale', lang)
      localStorage.setItem('rtl', this.$vuetify.rtl)
    }
  }
}
</script>
