<template>
  <v-app>
    <router-view/>
  </v-app>
</template>

<script>

import Direction from './Core/Mixins/Lang/Direction'
// import $ from 'jquery'
export default {
  name: 'App',
  data () {
    return {
      // btn: this.$t('fixedBtn')
    }
  },
  created () {
    window.addEventListener('resize', () => {
      this.$store.state.width = window.innerWidth
    })
  },
  mounted () {
  },
  mixins: [Direction]
}
</script>
<style lang="scss">
@import "assets/sass/main";
.fixed-btn {
  height: 115px;
  width: 115px;
  position: fixed;
  z-index: 10;
  left: calc((100vw - (115px) ) / 2) ;
  //bottom: 20px;
  //bottom: 73px;
  bottom: 35px;
  background: rgba(0,0,0,.25);
  border-radius: 50%;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border: 2px solid #F7F7F7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $--orange;
  text-decoration: underline;
  font-size: 12px;
  cursor: pointer;
  z-index: 10;
}
.fixed-btn > div {
  font-family: lama-extraBoldExpanded;
}
@media (max-width: 900px) {
  .fixed-btn {
    //bottom: 40px;
    bottom: 48px;
  }
}
@media (max-width: 600px) {
  .fixed-btn {
    height: 100px;
    width: 100px;
    left: calc((100vw - (110px) ) / 2);
    font-size: 11px;
  }
}
/* fonts */
@font-face {
  font-family: lama-bold-expanded;
  src: url("./assets/fonts/LamaBoldExpanded.otf");
}
@font-face {
  font-family: lama-regular;
  src: url("./assets/fonts/LamaRegular.otf");
}
@font-face {
  src: url("./assets/fonts/LamaSans-Regular.ttf");
  font-family: 'lama-regular';
}
@font-face {
  src: url("./assets/fonts/LamaSans-Bold.ttf");
  font-family: 'lama-bold';
}
@font-face {
  src: url("./assets/fonts/LamaSans-ExtraBold.ttf");
  font-family: 'lama-extraBold';
}
@font-face {
  src: url("./assets/fonts/LamaSans-ExtraBoldExpanded.ttf");
  font-family: 'lama-extraBoldExpanded';
}
@font-face {
  src: url("./assets/fonts/LamaSans-SemiBoldExpanded.ttf");
  font-family: 'lama-semiBoldExpanded';
}
@font-face {
  src: url("./assets/fonts/LamaSans-Medium.ttf");
  font-family: 'lama-medium';
}
.v-application {
  cursor: default;
  font-family: lama-regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $--black;
  font-size: 16px;
  background-size: cover!important;
  overflow: hidden;
  text-align: center!important;
}
/*scroll-handle*/
*::-webkit-scrollbar {
  width: 9px!important;
  height: 9px!important;
  background: rgba(255, 255, 255, 0.5)!important;
  border-radius: 20px!important;
  scroll-padding: 20px!important;
}
*::-webkit-scrollbar-thumb {
  background: rgba(0, 0,0, 0.15)!important;
  border-radius: 20px!important;
  opacity: 0.7!important;
}
*{
  font-family: lama-regular;
}
.rtl{
  direction: rtl !important;
}
.ltr{
  direction: ltr !important;
}
.rotate{
  transform: rotate(180deg);
}
.rotateY{
  transform: rotateY(180deg);
}
/*--------------------------------- safari ------------------------------*/
/*----------------------------------------------------------------------*/
</style>
<style>
@media not all and (min-resolution:.001dpcm) { @media {
  .fixed-btn{
    bottom: 40px;
  }
}}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
  .fixed-btn{
    bottom: 40px;
  }
}}
@media (max-height: 220px) {
  .fixed-btn{
    bottom: unset !important;
    top: 85px !important;
  }
}
</style>
